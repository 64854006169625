<template>
    <v-form>
        <v-overlay :value="overlay" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-col cols="12">
            <v-toolbar            
                dense
                color="primary"
            >
                <v-toolbar-title class="white--text">INGRESO DE PACIENTES</v-toolbar-title>
                <v-spacer></v-spacer>          
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            small
                            color="primary"
                            fab
                            v-bind="attrs"
                            v-on="on"
                            @click="agregarPaciente"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Agregar paciente</span>
                </v-tooltip>
            </v-toolbar>
        </v-col>
        <v-col 
            cols="12"
            v-for="(dp,x) in dataPacientes"
            :key="x"
        >
            <v-card class="px-2 mb-4">
                <v-toolbar 
                    elevation="0" 
                    dense
                >
                    <v-spacer></v-spacer>          
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                x-small
                                color="error"
                                fab
                                v-bind="attrs"
                                v-on="on"
                                @click="dataPacientes = dataPacientes.filter(e => e.uuid != dp.uuid)"
                            >
                                <v-icon>mdi-delete-forever</v-icon>
                            </v-btn>
                        </template>
                        <span>Eliminar paciente</span>
                    </v-tooltip>
                </v-toolbar>
                <v-row>
                    <v-col cols=12 md="6">
                        <v-text-field 
                            v-model="dp.first_name" 
                            dense 
                            label="Nombres"
                            :rules=required
                        />
                    </v-col>
                    <v-col cols=12 md="6">
                        <v-text-field 
                            v-model="dp.last_name" 
                            dense 
                            label="Apellidos"
                            :rules=required
                        />
                    </v-col>
                </v-row>            
                <v-row>
                    <v-col cols=12 md="6">
                        <v-select
                            :rules="dp.noValidaIdentificacion ? [] : required"
                            label="Tipo de identificación"
                            :items="partyIdentificationTypes"
                            item-text="description"
                            item-value="party_identification_type_id"
                            v-model="dp.party_identification_type_id"
                        ></v-select>
                    </v-col>
                    <v-col cols=12 md="6" style="margin-top: 19px;">
                        <v-text-field 
                            v-model="dp.id_value" 
                            dense 
                            label="Identificación"
                            :rules="dp.noValidaIdentificacion ? [] : required"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols=12>
                        <v-checkbox
                            v-model="dp.noValidaIdentificacion"
                            label="Identificación (NO APLICA)"
                            color="primary"
                            hide-details
                            class="font-weight-bold mt-0"                     
                        />
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
        <v-col class="text-center">
            <v-btn 
                @click="guardarPacientes"                
                color="green" 
                dark
                class="mx-1"
            >
                <v-icon>mdi-content-save</v-icon>Guardar
            </v-btn>
            <v-btn
                v-if="btnClose" 
                @click="$emit('close')"                
                color="default" 
                dark
                class="mx-1"
            >
                <v-icon>mdi-cancel</v-icon>Cerrar
            </v-btn>
        </v-col>
    </v-form>
</template>

<script>

import { mapState, mapMutations, mapActions } from "vuex";

export default{
    name: 'FormPacienteComponent',
    props:{
        asignaA: {
            type: String,
            require:true
        },
        quoteId: {
            type: String
        },
        btnClose:{
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        required: [v => !!v || 'El campo es requerido'],
        dataPacientes:[{
            uuid: Math.random().toString(36).substr(0, 20),
            first_name:'',
            last_name:'',
            id_value:'',
            party_identification_type_id:'',
            noValidaIdentificacion:false
        }],
        dataPacientesDefault:[{
            uuid: Math.random().toString(36).substr(0, 20),
            first_name:'',
            last_name:'',
            id_value:'',
            party_identification_type_id:'',
            noValidaIdentificacion:false
        }],
        partyIdentificationTypes: [],
        overlay: false
    }),
    computed: {

        ...mapState("master", ["loadingBtn", "companies"]),

        ...mapState("master", ["loadingTable", "tenantId", "user"]),

    },
    methods: {

        ...mapMutations('master', ['setUrl', 'setLoadingBtn']),

        ...mapActions('master', ['requestApi', 'alertNotification']),

        getTiposIdentificacion(){
            
            this.setUrl('asigna-paciente')

            this.requestApi({
                method: 'GET',
                data: {
                    typeList: 'partyIdentificationTypes',
                }
            }).then(res => {
                
                console.log('res',res)

                this.partyIdentificationTypes = res.data._embedded.asigna_paciente

            }).then(() => {
              
            })

        },

        agregarPaciente(){

            this.dataPacientes.push({
                uuid: Math.random().toString(36).substr(0, 20),
                first_name:'',
                last_name:'',
                id_value:'',
                party_identification_type_id:'',
            })

        },

        guardarPacientes(){

            this.setUrl('asigna-paciente')
            this.overlay=true

            this.requestApi({
                method: 'POST',
                data: {
                    pacientes: this.dataPacientes,
                    asignaA: this.asignaA,
                    quoteId: this.quoteId
                }
            }).then(res => {
                
                const { msg, success, pacientes } = res.data               

                console.log('res',res)

                this.alertNotification({
                    param:{
                        title: success ? 'Exito' : 'Error',
                        icon: success ? 'success' : 'error',
                        confirmButtonColor: success ? 'a5dc86' : 'red',
                        html: msg
                    }
                })

                if(success){

                    this.overlay = false
                    this.$emit('pacientes', pacientes)

                }

            }).catch(error => {
                
                console.log('error',error)
                this.overlay = false

            }).then(() => {  this.overlay = false })

        }

    },
    created(){

        this.getTiposIdentificacion()

    }
}

</script>