<template>
  <div>
    <v-overlay :value="overlay" absolute style="z-index:999999999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-row class="pa-4">
      <v-col cols="7">
        <v-card>
          <v-expansion-panels v-model="panel" flat>
            <v-expansion-panel>
              <v-expansion-panel-header disable-icon-rotate>
                <h3>1. Identificación del cliente</h3>
                <template v-slot:actions>
                  <v-icon color="green">
                    mdi-account
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-1">
                <v-form ref="formContacto">
                  <v-card class="pa-2">
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          :rules="requiredRule"
                          label="Nombres"
                          v-model="datosCliente.first_name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          :rules="requiredRule"
                          label="Apellidos"
                          v-model="datosCliente.last_name"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-select
                          :rules="requiredRule"
                          label="Tipo de identificación"
                          :items="tiposIdent"
                          item-text="nombre_ident"
                          item-value="tipo_ident_id"
                          v-model="datosCliente.party_identification_type_id"
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          :rules="requiredRule"
                          label="Num. Identificación"
                          v-model="datosCliente.num_ident"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          :rules="requiredRule"
                          label="Teléfono"
                          v-model="telefonoCli.contact_number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          :rules="validarEmail"
                          label="Email"
                          v-model="emailCli.email"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="6">
                        <v-autocomplete
                          :rules="requiredRule"
                          label="Provincia"
                          v-model="direccionCli.state_province_geo_id"
                          :items="provincias"
                          item-text="geo_name"
                          item-value="geo_id"
                          @change="cargarGeo('parroquias-provincia')"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete
                          :rules="requiredRule"
                          label="Ciudad"
                          v-model="direccionCli.parroquia_geo_id"
                          :items="cantones"
                          item-text="parroquia"
                          item-value="parroquia_id"
                          @change="cargarGeo('sectores-canton')"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete
                          :rules="this.sectores.length == 0 ? [] : requiredRule"
                          label="Sector"
                          v-model="direccionCli.sector"
                          :items="sectores"
                          item-text="nombre"
                          item-value="id"
                          clearable
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          label="Código postal"
                          v-model="direccionCli.postal_code"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Dirección"
                          v-model="direccionCli.address1"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn @click="guardarCliente" small color="green" dark
                          ><v-icon>mdi-content-save</v-icon>Guardar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-card>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-model="panel">
              <v-expansion-panel-header disable-icon-rotate>
                <h3>2. Datos de los pacientes</h3>
                <template v-slot:actions>
                  <v-icon color="blue">
                    mdi-account
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- <FormAsignaPacienteComponent
                  asignaA="QUOTE"
                  :quoteId=$route.params.quoteId
               /> -->
                <v-form ref="formPaciente">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        :rules="requiredRule"
                        label="Nombres"
                        v-model="datosPaciente.first_name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        :rules="requiredRule"
                        label="Apellidos"
                        v-model="datosPaciente.last_name"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-select
                        :rules="requiredRule"
                        label="Tipo de identificación"
                        :items="tiposIdent"
                        item-text="nombre_ident"
                        item-value="tipo_ident_id"
                        v-model="datosPaciente.party_identification_type_id"
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        :rules="requiredRule"
                        label="Num. Identificación"
                        v-model="datosPaciente.num_ident"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pt-2">
                    <v-col class="pa-0">
                      <v-checkbox
                        v-model="copiarDatosCliente"
                        label="copiar información del cliente"
                        @change="copiarInfoCliente"
                      ></v-checkbox>
                    </v-col>
                    <v-col class="pb-0">
                      <v-btn @click="guardarPaciente" small color="green" dark
                        ><v-icon>mdi-content-save</v-icon>Guardar</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-model="panel">
              <v-expansion-panel-header disable-icon-rotate>
                <h3>3. Confirmar cotización</h3>
                <template v-slot:actions>
                  <v-icon color="blue">
                    mdi-cash
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-toolbar flat>
                  <!-- <v-btn dark small color="green" @click="agregarPago"
                    ><v-icon>mdi-plus</v-icon></v-btn
                  > -->

                  <v-col cols="6">
                    <v-select
                      readonly
                      label="Plazo"
                      v-model="datosCotizacion.plazo_id"
                      :items="plazos"
                      item-text="plazo_nombre"
                      item-value="plazo_id"
                    ></v-select>
                  </v-col>
                </v-toolbar>
                <v-row>
                  <v-col cols="6">
                    <v-data-table
                      :items="pagos"
                      :headers="headerPagos"
                      hide-default-footer
                    ></v-data-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn @click="reservarCotizacion" small color="green" dark
                      ><v-icon>mdi-content-save</v-icon>Guardar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- <v-expansion-panel v-model="panel">
              <v-expansion-panel-header disable-icon-rotate>
                <h3>4. Revisar cotizacion</h3>
                <template v-slot:actions>
                  <v-icon color="blue">
                    mdi-eye
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card>
                  <v-data-table
                    hide-default-footer
                    :headers="headersItems"
                    :items="itemsCotizacion"
                  ></v-data-table>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel> -->
          </v-expansion-panels>
        </v-card>
      </v-col>
      <v-col cols="5">
        <v-card class="pa-4">
          <h2>totales</h2>
          <table style="border-collapse:collapse; padding:4px; width:50%">
            <tr>
              <td style="padding:6px; text-align:left">sub total</td>
              <td style="padding:6px; text-align:right">
                {{
                  (
                    parseFloat(totales.subTotalIva0) +
                    parseFloat(totales.subTotalIvaNoCero) +
                    parseFloat(totales.totalDescuento)
                  ).toFixed(2)
                }}
              </td>
            </tr>
            <tr>
              <td style="padding:6px; text-align:left">descuento</td>
              <td style="padding:6px; text-align:right">
                {{ totales.totalDescuento }}
              </td>
            </tr>
            <tr>
              <td style="padding:6px; text-align:left">total antes de IVA</td>
              <td style="padding:6px; text-align:right">
                {{
                  (
                    parseFloat(totales.subTotalIva0) +
                    parseFloat(totales.subTotalIvaNoCero)
                  ).toFixed(2)
                }}
              </td>
            </tr>
            <tr>
              <td style="padding:6px; text-align:left">total antes de IVA</td>
              <td style="padding:6px; text-align:right">
                {{ parseFloat(totales.totalIva).toFixed(2) }}
              </td>
            </tr>
            <tr>
              <td style=" padding:6px; border-top:1px solid #010101">
                <h2>
                  total
                </h2>
              </td>
              <td
                style=" border-top:1px solid #010101; text-align:right; padding:10px"
              >
                <h2>
                  {{ totales.totalConDescuento }}
                </h2>
              </td>
            </tr>
          </table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogPago" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Ingresar Pago</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="formPago">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12" sm="12" md="12">
                  <v-select
                    v-model="datosPago.payment_method_type_id"
                    small
                    item-text="description"
                    item-value="payment_method_type_id"
                    :items="paymentMethodTypes"
                    label="Forma de pago"
                    @change="cambioPmt()"
                  ></v-select>

                  <v-select
                    v-if="datosPago.payment_method_type_id == 'EFT_ACCOUNT'"
                    v-model="datosPago.fin_account_id"
                    small
                    item-text="fin_account_name"
                    item-value="fin_account_id"
                    :items="cuentasBancarias"
                    label="Cuenta bancaria"
                  ></v-select>

                  <v-select
                    v-if="
                      datosPago.payment_method_type_id == 'CREDIT_CARD' ||
                        datosPago.payment_method_type_id == 'CREDIT_CARD_DIF' ||
                        datosPago.payment_method_type_id ==
                          'CREDIT_CARD_DIF_INT' ||
                        datosPago.payment_method_type_id == 'DEBIT_CARD'
                    "
                    v-model="datosPago.payment_method_id"
                    small
                    item-text="card_type"
                    item-value="payment_method_id"
                    :items="tiposTarjetaMetodo"
                    label="Tipo de tarjeta"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="10" md="10" class="pt-0 pb-0">
                  <v-text-field
                    v-model="datosPago.effective_date"
                    label="Fecha"
                    type="date"
                    small
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="10" md="10" class="pt-0 pb-0">
                  <v-text-field
                    v-model="datosPago.payment_ref_num"
                    label="Referencia"
                    type="text"
                    small
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="8" class="pt-0 pb-0">
                  <v-text-field
                    v-model="datosPago.monto"
                    label="Monto pagado"
                    type="text"
                    small
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*campos obligatorios</small>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            small
            text
            @click="dialogPago = false"
            class="px-4 mb-4"
          >
            <v-icon>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            solid
            small
            class="white--text mb-4"
            @click="ingresarPago()"
          >
            <v-icon>mdi-content-save</v-icon>Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import FormAsignaPacienteComponent from './FormAsignaPacienteComponent.vue';

export default {
  name: "CotizacionCheckout",
  components: {
    FormAsignaPacienteComponent
  },
  data: () => ({
    show: false,
    panel: 0,
    requiredRule: [(v) => !!v || "El campo es requerido"],

    options: {},
    setfilters: [],
    selected: [],
    users: [],
    totalItems: 1,
    currentPage: 1,
    itemsPerPage: 50,
    pageCount: 1,

    required: [(v) => !!v || "El campo es requerido"],

    headers: [
      { text: "ID", align: "start", value: "sales_opportunity_id" },
      { text: "Nombre", align: "start", value: "opporunity_name" },
      { text: "Cliente", align: "start", value: "cliente_nombre" },
      { text: "Estado", align: "start", value: "sopp_estado" },
      { text: "Monto", align: "start", value: "estimated_amount" },
      {
        text: "Cierre estimado",
        align: "start",
        value: "estimated_close_date",
      },
      { text: "Asignado a", align: "start", value: "created" },
    ],

    datosOportunidad: {
      sales_opportunity_id: "",
      party_id: "",
      monto: 0,
      fecha_estimada_cierre: "",
      estado_id: "",
      estado: "",
      opportunity_name: "",
      cliente_nombres: "",
      description: "",
      next_step: "",
    },

    //establishmentSelected: "10010",
    datosCotizacion: {
      quote_id: "",
      party_id: "",
      lead_nombres: "",
      fecha: "",
      quote_id: "",
      estado_cot: "",
      status_id: "",
      opp_id: "",
    },

    headersItems: [
      { text: "Accion", align: "start", value: "opciones" },
      { text: "Sec.", align: "start", value: "quote_item_seq_id" },
      { text: "Producto", align: "start", value: "product_name" },
      { text: "Cantidad", align: "end", value: "quantity" },
      { text: "Valor unitario", align: "end", value: "quote_unit_price" },
      { text: "Valor Descuento", align: "end", value: "valor_descuento" },
      { text: "%IVA", align: "end", value: "pct_impuesto" },
      { text: "Total", align: "end", value: "valor_total" },
    ],
    itemsCotizacion: [],
    totales: {},

    paymentMethodTypes: [
      { payment_method_type_id: "CREDITO", description: "CREDITO" },
      { payment_method_type_id: "CASH", description: "EFECTIVO" },
      {
        payment_method_type_id: "EFT_ACCOUNT",
        description: "TRANSFERENCIA ELECTRONICA",
      },
      {
        payment_method_type_id: "CREDIT_CARD",
        description: "TARJ. DE CRÉDITO CORRIENTE",
      },
      {
        payment_method_type_id: "CREDIT_CARD_DIF_INT",
        description: "TARJ. DE CREDITO DIFERIDO CON INT.",
      },
      {
        payment_method_type_id: "DEBIT_CARD",
        description: "TARJ.DE DEBITO",
      },
      {
        payment_method_type_id: "PLACE_TO_PAY_CC",
        description: "PLACE TO PAY TARJ. DE CREDITO ",
      },
      {
        payment_method_type_id: "PLACE_TO_PAY_DC",
        description: "PLACE TO PAY TARJ. DE DEBITO ",
      },
    ],
    plazos: [
      { plazo_id: "CONTADO", plazo_nombre: "CONTADO" },
      { plazo_id: "30DIAS", plazo_nombre: "30 DIAS" },
      { plazo_id: "60DIAS", plazo_nombre: "60 DIAS" },
      { plazo_id: "90DIAS", plazo_nombre: "90 DIAS" },
    ],
    tipos_plazo: [
      { tplazo_id: "CONTADO", tplazo_nombre: "CONTADO" },
      { tplazo_id: "CREDITO", tplazo_nombre: "CREDITO" },
    ],

    overlay: false,
    busCedula: "",
    datosCliente: {},
    direccionCli: {},
    telefonoCli: { contact_number: "" },
    emailCli: { email: "" },
    datosPaciente: {},
    datosPrescriptor: {
      nombres: "",
      apellidos: "",
      tipoIdent: "",
      numIdent: "",
    },
    provincias: [],
    cantones: [],
    sectores: [],
    tiposIdent: [
      { tipo_ident_id: "CEDULA", nombre_ident: "CEDULA" },
      { tipo_ident_id: "RUC", nombre_ident: "RUC" },
      { tipo_ident_id: "PASAPORTE", nombre_ident: "PASAPORTE" },
    ],
    reservado: false,
    facturado: false,
    invoice_id: "",
    invoice_number: "",
    copiarDatosCliente: true,

    headerPagos: [
      {
        text: "Forma de pago",
        align: "start",
        value: "forma_pago",
      },

      { text: "Valor", align: "end", value: "valor" },
    ],
    pagos: [],
    datosPago: {},
    cuentasBancarias: [],
    dialogPago: false,
  }),
  computed: {
    ...mapState("master", ["loadingBtn", "companies"]),
    ...mapState("crm", ["menu", "loadingBtn", "paramAlertQuestion"]),
    ...mapState("master", ["loadingTable", "tenantId", "user"]),
    /*validarSector() {
      if (this.direccionCli.sector == "" || this.direccionCli.sector == null) {
        return ["Seleccione un sector"];
      } else {
        return [];
      }
    },*/

    validarEmail() {
      if (this.emailCli.email == "") {
        return [true];
      }
      if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          this.emailCli.email
        )
      ) {
        return ["Email inválido"];
      } else {
        return [true];
      }
    },
    formasPagoPermiso() {
      return this.paymentMethodTypes.filter((item) => {
        if (item.payment_method_type_id == "BAJA_CARTERA_CC") {
          return this.puedoBajaCarteraCliente;
        } else if (item.payment_method_type_id == "PTMO_EMPLEADO") {
          return this.puedoBajaCarteraCliente;
        } else {
          return true;
        }
      });
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setLoadingTable",
      "setTitleToolbar",
      "setTenantId",
      "setDrawer",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapActions("access", []),

    cargarCheckoutCotizacion() {
      this.setLoadingTable(true);
      this.overlay = true;
      this.setUrl("crm-cotizacion");
      this.requestApi({
        method: "POST",
        data: {
          accion: "datosCheckout",
          quoteId: this.$route.params.quoteId,
        },
      })
        .then((res) => {
          //console.log(res.data);
          this.datosCotizacion = res.data.detail.cotizacion;
          //this.datosOportunidad = res.data.detail.oportunidad;
          this.itemsCotizacion = res.data.detail.items;
          this.datosCliente = res.data.detail.datos_lead;
          ///this.totales = res.data.detail.cotizacion.totales;
          this.telefonoCli = res.data.detail.telefono;
          this.emailCli =
            res.data.detail.email.length == 0 ? {} : res.data.detail.email;
          this.direccionCli =
            res.data.detail.direccion.length > 1
              ? res.data.detail.direccion[0]
              : res.data.detail.direccion.length == 0
              ? {}
              : res.data.detail.direccion;
          this.reservado = res.data.detail.reservado;
          this.facturado = res.data.detail.facturado;
          this.invoice_id = res.data.detail.invoice_id;
          this.invoice_number = res.data.detail.invoice_number;
          this.provincias = res.data.detail.provincias;
          this.totales = res.data.detail.totales;
          this.cantones = res.data.detail.cantones;
          this.sectores = res.data.detail.sectores;
          this.datosPaciente = res.data.detail.datosPaciente;

          this.pagos.push({
            forma_pago: this.datosCotizacion.forma_pago,
            payment_method_type_id: this.datosCotizacion.payment_method_type_id,
            valor: this.totales.totalConDescuento,
            tipo_cuenta: "",
            referencia: "",
          });
          this.overlay = false;
        })
        .then(() => {
          this.setLoadingTable(false);
          this.overlay = false;
        });
    },
    guardarPaciente() {
      if (!this.$refs.formPaciente.validate()) {
        return false;
      }
      this.panel = 2;
    },
    reservarCotizacion() {
      //console.log(this.emailCli);
      this.setLoadingTable(true);
      this.setUrl("crm-cotizacion");
      this.requestApi({
        method: "POST",
        data: {
          accion: "guardarClienteCheckout",
          quote_id: this.datosCotizacion.quote_id,
          product_store_id: this.datosCotizacion.product_store_id,
          datosCliente: this.datosCliente,
          datosPaciente: this.datosPaciente,
          telefonoCli: this.telefonoCli,
          emailCli: this.emailCli,
          direccionCli: this.direccionCli,
        },
      })
        .then((res) => {
          console.log(res.data);
          this.alertNotification({
            param: { html: res.data.msg },
          });

          this.$router.push({
            path: "/cotizacion/" + this.datosCotizacion.quote_id,
          });

          //this.cargarCheckoutCotizacion();
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    guardarCliente() {
      if (!this.$refs.formContacto.validate()) {
        return false;
      }

      this.copiarInfoCliente();

      this.panel = 1;
    },

    cargarGeo(tipo) {
      let id = "";
      if (tipo == "parroquias-provincia") {
        id = this.direccionCli.state_province_geo_id;
        this.direccionCli.parroquia_geo_id = "";
        this.direccionCli.sector = "";
      }
      if (tipo == "sectores-canton") {
        this.direccionCli.canton_geo_id = null;
        for (let i = 0; i < this.cantones.length; i++) {
          if (
            this.cantones[i].parroquia_id == this.direccionCli.parroquia_geo_id
          ) {
            this.direccionCli.canton_geo_id = this.cantones[i].canton_id;
          }
        }
        id = this.direccionCli.canton_geo_id;
        this.direccionCli.sector = "";
      }
      this.setLoadingTable(true);
      this.setUrl("geo/" + id + "/" + tipo);
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          console.log(res.data.detail);
          if (tipo == "parroquias-provincia") {
            this.cantones = res.data.detail;
          }
          if (tipo == "sectores-canton") {
            this.sectores = res.data.detail;
          }
        })
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    copiarInfoCliente() {
      if (this.copiarDatosCliente == true) {
        this.datosPaciente.party_id = this.datosCliente.party_id;
        this.datosPaciente.first_name = this.datosCliente.first_name;
        this.datosPaciente.last_name = this.datosCliente.last_name;
        this.datosPaciente.party_identification_type_id = this.datosCliente.party_identification_type_id;
        this.datosPaciente.num_ident = this.datosCliente.num_ident;
      } else {
        this.datosPaciente.party_id = "";
        this.datosPaciente.first_name = "";
        this.datosPaciente.last_name = "";
        this.datosPaciente.party_identification_type_id = "";
        this.datosPaciente.num_ident = "";
      }
    },
    agregarPago() {
      this.dialogPago = true;
    },
    cargarCuentasBancarias() {
      this.setUrl("cuentas-bancarias");
      this.requestApi({
        method: "GET",
        data: {},
      }).then((res) => {
        this.cuentasBancarias = res.data._embedded.cuentas_bancarias;
      });
    },

    cambioPmt() {
      this.datosPago.payment_method_id = "";
      this.datosPago.fin_account_id = "";
    },
  },

  mounted() {
    this.setTitleToolbar("FINALIZAR COTIZACION");
    this.cargarCuentasBancarias();
    this.cargarCheckoutCotizacion();
    //this.setDrawer(false);
  },
};
</script>
